import React, { useState } from "react"
import Tabs from "../../../../molecules/tabs"
import Button from "../../../../fundamentals/button"
import PaymentTable from "../../../../templates/payments-table/index"

const Payments = () => {
  const [currentTab, setCurrentTab] = useState<Number>(0)

  return (
    <div>
      <Tabs currentTab={currentTab} setCurrentTab={setCurrentTab} />

      {currentTab === 0 && (
        <>
          <div className="flex  flex-col items-center justify-center flex-1 group bg-grey-0 rounded-rounded p-large border border-grey-20 h-full mt-10">
            <div className="flex w-full">
              <div className="w-[35%] flex flex-col">
                <h2 className="font-semibold">Thanh toán QR</h2>
                <span>
                  - Tận hưởng thâm ưu đãi để tăng khách hàng và doanh thu
                </span>
                <span>
                  - Thanh toán tiện lợi với mã QR động gắn với từng đơn hàng
                </span>
                <span>- Hỗ trợ báo cáo, giao dịch</span>
              </div>
              <div className="w-[65%] bg-grey-20 p-6 rounded-rounded flex justify-between">
                <div className="flex flex-col">
                  <h2 className="font-semibold">VNPAY-QR</h2>
                  <span>
                    Bạn chưa cấu hình thanh toán qua VNPAY-QR. Để tìm hiểu cách
                    kết nối và sử dụng vui lòng xem{" "}
                    <a className=" text-blue-30 cursor-pointer">tại đây</a>
                    <Button
                      className="rounded-rounded mt-4 w-[320px] inter-base-regular"
                      variant="primary"
                      size="medium"
                    >
                      Kết nối
                    </Button>
                  </span>
                </div>
                <div>
                  <img
                    className="w-[140px] "
                    src="https://gateway-file-donate.hn.ss.bfcplatform.vn/monmi-ecomerce/vnpay.webp"
                  />
                </div>
              </div>
            </div>
            <div className="h-[30px]"></div>
            <div className="flex w-full">
              <div className="w-[35%] flex flex-col"></div>
              <div className="w-[65%] bg-grey-20 p-6 flex justify-between rounded-rounded">
                <div className="flex flex-col">
                  <h2 className="font-semibold">ShoppePay-QR</h2>
                  <span>
                    Bạn chưa cấu hình thanh toán qua ShoppePay-QR. Để tìm hiểu
                    cách kết nối và sử dụng vui lòng xem{" "}
                    <a className=" text-blue-30 cursor-pointer">tại đây</a>
                    <Button
                      className="rounded-rounded mt-4 w-[320px] inter-base-regular"
                      variant="primary"
                      size="medium"
                    >
                      Kết nối
                    </Button>
                  </span>
                </div>
                <div>
                  <img
                    className="w-[140px]"
                    src="https://gateway-file-donate.hn.ss.bfcplatform.vn/monmi-ecomerce/shopeepay.webp"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex  flex-col items-center justify-center flex-1 group bg-grey-0 rounded-rounded p-large border border-grey-20 h-full mt-10">
            <div className="flex w-full">
              <div className="w-[35%] flex flex-col">
                <h2 className="font-semibold">Cổng thanh toán online</h2>
                <span>
                  - Người bán tạo link thanh toán online tiện lợi với đa dạng
                  các phương thức thanh toán
                </span>
                <span>- Người mua thanh toán dễ dàng mọi lúc mọi nơi</span>
                <span>
                  - Quản lý đồng bộ trên Sapo, hỗ trợ báo cáo, đối soát
                </span>
              </div>
              <div className="w-[65%] bg-grey-20 flex justify-between p-6 rounded-rounded">
                <div className="flex flex-col">
                  <h2 className="font-semibold">PayOn</h2>
                  <span>
                    Bạn chưa cấu hình thanh toán qua PayOn. Để tìm hiểu cách kết
                    nối và sử dụng vui lòng xem{" "}
                    <a className=" text-blue-30 cursor-pointer">tại đây</a>
                    <Button
                      className="rounded-rounded mt-4 w-[320px] inter-base-regular"
                      variant="primary"
                      size="medium"
                    >
                      Kết nối
                    </Button>
                  </span>
                </div>
                <div>
                  <img
                    className="w-[140px]"
                    src="https://gateway-file-donate.hn.ss.bfcplatform.vn/monmi-ecomerce/payon.webp"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex  flex-col items-center justify-center flex-1 group bg-grey-0 rounded-rounded p-large border border-grey-20 h-full mt-10">
            <div className="flex w-full">
              <div className="w-[35%] flex flex-col">
                <h2 className="font-semibold">Chuyển khoản VietQR</h2>
                <span>
                  - Chuyển khoản nhanh 24/7 theo chuẩn VietQR, tiền về tài khoản
                  ngay lập tức
                </span>
                <span>
                  - Tự động gạch nợ đơn hàng khi thanh toán thành công.
                </span>
                <span>
                  - Quản lý đồng bộ trên Sapo, hỗ trợ báo cáo, đối soát
                </span>
              </div>
              <div className="w-[65%] bg-grey-20 flex flex-col p-6 rounded-rounded">
                <h2 className="font-semibold">OCB-VietQR</h2>
                <span>
                  Bạn chưa cấu hình thanh toán qua OCB-VietQR. Để tìm hiểu cách
                  kết nối và sử dụng vui lòng xem{" "}
                  <a className=" text-blue-30 cursor-pointer">tại đây</a>
                </span>
                <span>
                  <span style={{ color: "red" }}>Lưu ý</span>: Bạn cần có tài
                  khoản OCB để thực hiện kết nối. Nếu chưa có tài khoản vui lòng
                  đăng ký{" "}
                  <a className=" text-blue-30 cursor-pointer">tại đây</a>
                </span>
                <Button
                  className="rounded-rounded mt-4 w-[320px] inter-base-regular"
                  variant="primary"
                  size="medium"
                >
                  Kết nối
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
      {currentTab === 1 && (
        <div className="flex  flex-col items-center justify-center flex-1 group bg-grey-0 rounded-rounded p-large border border-grey-20 h-full mt-10">
          <div className="flex w-full">
            <div className="w-[35%] flex flex-col">
              <h2 className="font-semibold">Phương thức thanh toán thủ công</h2>
              <span>Thêm mới các hình thức thanh toán khác</span>
              <Button
                className="rounded-rounded mt-4 w-[320px] inter-base-regular"
                variant="primary"
                size="small"
              >
                Thêm mới
              </Button>
            </div>
            <div className="w-[65%]">
              <PaymentTable />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Payments
